<template>
  <footer class="app-footer" id="thehub_footer">
    <b-container class="app-footer__container">
      <div class="app-footer__information">
        <img
          src="~/assets/img/logo-white.svg"
          alt="The Hub logo"
          width="70"
          height="32"
          class="app-footer__information__logo mb-24"
        />
        <p class="fs15">
          We connect global talents with career opportunities in Nordic startups.
        </p>
        <div class="app-footer__information__flags">
          <img src="~/assets/img/icons/flags/dk.svg" alt="DK flag" width="24" height="24" />
          <img src="~/assets/img/icons/flags/se.svg" alt="SE flag" width="24" height="24" />
          <img src="~/assets/img/icons/flags/no.svg" alt="NO flag" width="24" height="24" />
          <img src="~/assets/img/icons/flags/fi.svg" alt="SE flag" width="24" height="24" />
          <img
            src="~/assets/img/icons/flags/is.svg"
            style="margin-left: -1px"
            alt="IS flag"
            width="26"
            height="25"
          />
        </div>
        <div>
          <h4 class="app-footer__column__heading">MAIN PARTNERS</h4>
          <div style="display: flex; align-items: center;">
            <img
              src="~/assets/img/mesh_logo.png"
              alt="Mesh logo"
              width="90"
              height="26"
              class="app-footer__information__logo mb-24"
            />
            <div style="width: 1rem;"></div>
            <img
              src="~/assets/img/dbg_logo.png"
              alt="Danske Bang Growth logo"
              width="160"
              height="22"
              class="app-footer__information__logo mb-24"
            />
          </div>
        </div>
      </div>
      <div class="app-footer__columns">
        <div class="app-footer__column">
          <h4 class="app-footer__column__heading">For Candidates</h4>
          <router-link class="link" to="/jobs">Explore jobs</router-link>
          <router-link class="link" to="/jobs?countryCode=REMOTE&sorting=mostPopular"
            >Explore remote jobs</router-link
          >
          <router-link class="link" to="/startups">Explore startups</router-link>
          <a href="https://insights.thehub.io/" target="_blank" class="link">Explore content</a>
        </div>

        <div class="app-footer__column">
          <h4 class="app-footer__column__heading">For startups</h4>
          <router-link class="link" to="/startups/join">Overview</router-link>
          <router-link class="link" to="/pricing">Pricing</router-link>
          <router-link class="link" to="/scout">Scout</router-link>
          <router-link class="link" to="/funding">Investor list</router-link>
          <router-link class="link" to="/startups/career-page">Embed Career Page</router-link>
          <router-link class="link" to="/deals">Startup Deals</router-link>
          <router-link class="link" to="/community-program">Community Program</router-link>
        </div>
        <div class="app-footer__column">
          <h4 class="app-footer__column__heading">Company</h4>
          <router-link class="link" to="/about">About</router-link>
          <a href="https://insights.thehub.io/" target="_blank" class="link">Blog</a>
          <router-link class="link" to="/contact">Contact</router-link>
          <router-link class="link" to="/partners">Partners</router-link>
        </div>

        <div class="app-footer__column">
          <h4 class="app-footer__column__heading">Explore</h4>
          <a href="https://www.linkedin.com/company/thehubio/" target="_blank" class="link"
            >LinkedIn</a
          >
          <a href="https://www.facebook.com/thehub.io" target="_blank" class="link">Facebook</a>
          <a href="https://www.instagram.com/thehubio" target="_blank" class="link">Instagram</a>
          <a href="https://twitter.com/thehubio" target="_blank" class="link">Twitter</a>
        </div>

        <div class="app-footer__column mobile visible-sm-down">
          <router-link class="link" to="/terms">Terms</router-link>
          <router-link class="link" to="/privacy-policy">Privacy</router-link>
          <p class="copyright">© 2024 thehub.io</p>
        </div>
      </div>
    </b-container>
    <b-container class="app-footer__copyright hidden-sm-down">
      <p>© 2024 thehub.io</p>
      <router-link class="link" to="/terms">Terms</router-link>
      <router-link class="link" to="/privacy-policy">Privacy</router-link>
    </b-container>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter',
  computed: {
    siteCountries() {
      return this.$store.getters['meta/siteCountriesExNI'];
    },
    userCurrentProfile() {
      return this.$store.getters['user/currentProfile'];
    }
  }
};
</script>

<style scoped lang="scss">
.app-footer {
  position: relative;
  background-color: $gray-900;
  color: $white;
  overflow: hidden;

  &__container {
    position: relative;
    display: flex;
    flex-direction: row;
    padding-top: rem(120px);

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      padding: rem(56px);
    }
  }

  &__information {
    width: rem(335px);

    p {
      line-height: 1.6;
      max-width: 233px;
    }

    &__flags {
      margin: 38px 0;

      @include media-breakpoint-down(sm) {
        margin: rem(56px) 0;
      }

      img {
        margin-right: 10px;
      }
    }
  }

  &__column {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding-right: rem(32px);

    @include media-breakpoint-down(sm) {
      padding-bottom: rem(56px);
    }

    &__heading {
      color: $teal;
      text-transform: uppercase;
      font-size: rem(15px);
      opacity: 0.6;
      margin-bottom: rem(16px);
    }

    a,
    .link {
      text-decoration: none;
      color: $white;
      margin-bottom: rem(8px);
      line-height: 1.6;

      &:hover,
      &:active,
      &:focus {
        text-decoration: none;
        color: $baltic-blue;
      }
    }
  }

  &__columns {
    display: flex;
    flex-direction: row;
    flex-grow: 1;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }

    .mobile {
      opacity: 0.7;
    }

    .copyright {
      margin-top: rem(56px);
    }
  }

  &__copyright {
    position: relative;
    display: flex;
    flex-direction: row;
    opacity: 0.7;
    color: $white;
    font-size: rem(15px);
    padding-bottom: 28px;

    p {
      margin-right: rem(36px);
    }

    a {
      margin-right: rem(16px);
      text-decoration: none;
      color: $white;

      &:hover,
      &:active,
      &:focus {
        text-decoration: none;
        color: $baltic-blue;
      }
    }
  }
}
</style>
